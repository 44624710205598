import { Component, OnInit, NgZone } from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-facial-auth',
    templateUrl: './facial-auth.component.html',
    styleUrls: ['./facial-auth.component.scss']
})
export class FacialAuthComponent implements OnInit {

    public allowedVideo = false;
    public valid = true;
    constructor(private ngZone: NgZone, private router: Router) { }

    ngOnInit() {
        console.log("pin", (window as any).pin);
        const constraints = { audio: false, video: { width: 1280, height: 720 } };
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia(constraints)
                .then((mediaStream) => {
                    const video = document.querySelector('video');
                    video.srcObject = mediaStream;
                    video.onloadedmetadata = (e) => {
                        video.play();
                        this.allowedVideo = true;
                    };
                })
                .catch((err) => { console.log(err.name + ': ' + err.message); }); // always check for errors at the end.
        }

        (window as any).AWS.config.update({
            accessKeyId: "AKIA35AZQAGE6UOKFNMM",
            secretAccessKey: "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",
            region: "eu-central-1"
        });
    }

    blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                resolve((reader.result as any).split(",")[1]);
            }
        })
    }

    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    captureImage() {
        return new Promise((resolve, reject) => {
            var canvas: any = document.getElementById('canvas');
            var video: any = document.getElementById('video');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            canvas.toBlob((blob) => {
                this.blobToBase64(blob).then((base64) => {
                    resolve(this._base64ToArrayBuffer(base64));
                });
            })
        })

    }


    getPersonPhoto(pin) {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await fetch(`http://snf-49523.vm.okeanos-global.grnet.gr:3000/photo/${pin}`)
                let json = await res.json()
                resolve(json.photo);
            }
            catch (e) {
                resolve(false)
            }
        })
    }

    async capture() {
        const pin = (window as any).pin;
        this.valid = true;
        (this.valid as any) = await this.compareFaces(pin);
        console.log('Recognition complete:', this.valid);
        if (this.valid) {
            const userExists = await this.getUser(pin);
            (window as any).exists = userExists;
            if (userExists) {
                window.location.href = 'https://asanlogintest.my.gov.az/auth';
            } else {
                this.router.navigate(['/create']);
            }
        } else {
            this.valid = false;
            setTimeout(
                this.navigateToLogin.bind(this), 5000);
        }
    }

    navigateToLogin() {
        this.router.navigate(['/'])
    }
    //Calls DetectFaces API and shows estimated ages of detected faces
    compareFaces(pin) {
        return new Promise(async (resolve, reject) => {
                (window as any).AWS.region = "eu-central-1";
                var rekognition = new (window as any).AWS.Rekognition();


                let APIimage = await this.getPersonPhoto(pin)

                if(!APIimage) {
                    resolve(false);
                    return;
                }

                let cameraPhoto = await this.captureImage()


                var params = {
                    SourceImage: { /* required */
                        Bytes: cameraPhoto
                    },
                    TargetImage: { /* required */
                        Bytes: this._base64ToArrayBuffer( APIimage)
                    },
                    SimilarityThreshold: 90
                };
                rekognition.compareFaces(params, function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else {
                    if(data.FaceMatches[0] && data.FaceMatches[0].Similarity > 80){
                        resolve(true)
                    }
                    else {
                        resolve(false)
                    }
                }
                })

        });
    }



  async getUser(pin) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await fetch(`http://snf-49523.vm.okeanos-global.grnet.gr:3000/user/${pin}`)
            resolve(res.status === 200);
        }
        catch (e) {
            resolve(false)
        }
    })
}
}
