import { Router } from "@angular/router";
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router) { }
    public validPin = true;

  ngOnInit() {
    //   (window as any).init();
  }

  async login(pin: string) {
    //   TODO: call login service
    (window as any).pin = pin;
    try {
        this.validPin = true;
        // const user = await this.getUser(pin);
        // if (user) {
        // (window as any).pin = pin;
        this.router.navigate(['/face-scan-login']);
        // }
        // else this.validPin = false;
    } catch (e) {
        console.log('Pin doesn\'t exist:', e);
    }
  }


  async getUser(pin) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await fetch(`http://snf-49523.vm.okeanos-global.grnet.gr:3000/user/${pin}`)
            resolve(res.status === 200);

        }
        catch (e) {
            resolve(false)
        }
    })
}
}
