import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

        public old_pin: string = "";
        public new_pin: string = "";
        public confirm_pin: string = "";
        public submitted = false;
        public create: boolean = (window as any).exists;
        constructor() { }
    
        ngOnInit() {
        }
    
        resetPassword() {
            console.log(this.old_pin, this.new_pin, this.confirm_pin);
            this.old_pin = "";
            this.new_pin = "";
            this.confirm_pin = "";
            this.submitted = true;
        }
    }
    