import { CreateAccountComponent } from "./components/create-account/create-account.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { FacialAuthComponent } from "./components/facial-auth/facial-auth.component";
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'face-scan-login', component: FacialAuthComponent },
    { path: 'face-scan-register', component: FacialAuthComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'create', component: CreateAccountComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
